.App {
    text-align: center;
}

.warranty-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.cert-values {

    font-size: 16px;
    line-height: 3;
}

.cert-title {
    font-size: 18px;
    font-weight: bold;
}

.logo-style {
    max-width: 300px;
    margin: 0 auto;
}